import mapVerify from '@/utils/formValidate'
import myTransform from '@/libs_sz/utils/transform'

const getFormatter = (data, value) => {
  const getValue = myTransform.arrToObject(data, 'value', 'label')[value] || value
  return getValue === 'null' || getValue === 0 ? '' : getValue
}

export const getMoreQueryFormData = (that, row) => {
  const moreQueryData = [
    // 物料编码
    {
      name: 'materialCode',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.material.materialCode')
    },
    // 物料名称
    {
      name: 'materialName',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.material.materialName'),
      clearable: true
    },
    // 启用状态
    {
      name: 'materialStatus',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.enableStatus'),
      clearable: true,
      data: that.statusFlagList || []
    },
    // 一级分类
    {
      name: 'classifyFirst',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.material.primaryClassify'),
      clearable: true,
      filterable: true,
      data: that.materialClassifyFirstList || [],
      mchange(val, item, cb) {
        that.getClassifyByFirstClassify(val)
        cb({ classifySecond: '' })
      }
    },
    // 二级分类
    {
      name: 'classifySecond',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.material.secondaryClassify'),
      clearable: true,
      filterable: true,
      data: that.classifySecondList
    },
    // 外部物料号
    {
      name: 'materialHostCode',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.material.externalItemNo'),
      clearable: true
    }
  ]
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 物料编码
    {
      prop: 'materialCode',
      label: that.$t('lang.gles.material.materialCode'),
      slotName: 'clickSlot',
      isShow: true
    },
    // 物料名称
    {
      prop: 'materialName',
      label: that.$t('lang.gles.material.materialName'),
      isShow: true
    },
    // 一级分类
    {
      prop: 'classifyFirst',
      label: that.$t('lang.gles.material.primaryClassify'),
      formatter(row, column) {
        return getFormatter(that.materialClassifyFirstList, row[column])
      },
      isShow: true
    },
    // 二级分类
    {
      prop: 'classifySecond',
      label: that.$t('lang.gles.material.secondaryClassify'),
      formatter(row, column) {
        return getFormatter(that.materialClassifySecondList, row[column])
      },
      isShow: true
    },
    // 启用状态
    {
      prop: 'materialStatus',
      label: that.$t('lang.gles.common.enableStatus'),
      minWidth: 84,
      formatter(row, column) {
        return getFormatter(that.statusFlagList, row[column])
      },
      isShow: true
    },
    // 基本单位
    {
      prop: 'basicUnit',
      label: that.$t('lang.gles.common.basicUnit'),
      minWidth: 84,
      formatter(row, column) {
        return getFormatter(that.basicUnitList, row[column])
      },
      isShow: true
    },
    // 重量
    {
      prop: 'weight',
      label: that.$t('lang.gles.common.weight'),
      isShow: true
    },
    // 体积
    {
      prop: 'volume',
      label: that.$t('lang.gles.common.volume'),
      isShow: true
    },
    // 有效期
    {
      prop: 'expiration',
      label: that.$t('lang.gles.common.termValidity'),
      isShow: true
    },
    // 创建人
    {
      prop: 'createUser',
      label: that.$t('lang.gles.common.createUser'),
      minWidth: 72,
      isShow: true
    },
    // 创建时间
    {
      prop: 'createTime',
      label: that.$t('lang.gles.common.createTime'),
      minWidth: 160,
      formatter: {
        type: 'time'
      },
      isShow: true
    },
    // 编辑人
    {
      prop: 'updateUser',
      label: that.$t('lang.gles.common.updateUser'),
      minWidth: 72,
      isShow: true
    },
    // 编辑时间
    {
      prop: 'updateTime',
      label: that.$t('lang.gles.common.updateTime'),
      minWidth: 160,
      formatter: {
        type: 'time'
      },
      isShow: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const editMaterialFile = [
    // 物料编码
    {
      name: 'materialCode',
      value: row.materialCode || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.material.materialCode'),
      rules: mapVerify(['required', 'inputCode']),
      clearable: true
    },
    // 物料名称
    {
      name: 'materialName',
      value: row.materialName || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.material.materialName'),
      rules: mapVerify(['required', 'inputName']),
      clearable: true
    },
    // 启用状态
    {
      name: 'materialStatus',
      value: row.materialStatus ?? 0,
      span: 8,
      component: 'elSwitch',
      activeValue: 0,
      inactiveValue: 1,
      activeText: that.$t('lang.gles.common.enable'),
      inactiveText: that.$t('lang.gles.common.disable'),
      label: that.$t('lang.gles.common.enableStatus'),
      class: 'switch'
    }
  ]
  // 给每一项绑定mchange事件
  if (that.mode === 'detail') {
    editMaterialFile.forEach((item) => {
      item.disabled = true
      if (!item.mchange) {
        item.mchange = that.onFormChange
      }
    })
  }
  return editMaterialFile
}

// 获取物料基本信息
export const getEditBasicFormData = (that, options, row, mode) => {
  const basicFormData = [
    // 基本单位
    {
      name: 'basicUnit',
      value: row.basicUnit || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.common.basicUnit'),
      rules: mapVerify(['required']),
      clearable: true,
      data: options.basicUnitList || []
    },
    // 计量单位
    {
      name: 'measuringUnit',
      value: row.measuringUnit || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.common.measureUnit'),
      clearable: true,
      data: options.measuringUnitList || []
    },
    // 外部物料号
    {
      name: 'materialHostCode',
      value: row.materialHostCode || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.material.externalItemNo'),
      rules: mapVerify(['inputLen50']),
      clearable: true
    },
    // 一级分类
    {
      name: 'classifyFirst',
      value: row.classifyFirst || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.material.primaryClassify'),
      filterable: true,
      data: options.materialClassifyFirstList || [],
      mchange(val, item, cb) {
        that.getClassifyByFirstClassify(val)
        cb({ classifySecond: '' })
      }
    },
    // 二级分类
    {
      name: 'classifySecond',
      value: row.classifySecond || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.material.secondaryClassify'),
      clearable: true,
      filterable: true,
      data: mode === 'detail' ? options.materialClassifySecondList : (that.classifySecondList || [])
    },
    // 长
    {
      name: 'length',
      value: row.length || '',
      span: 8,
      component: 'elInput',
      rules: mapVerify(['positiveNum']),
      label: `${that.$t('lang.gles.base.length')}(mm)`,
      clearable: true
    },
    // 宽
    {
      name: 'width',
      value: row.width || '',
      span: 8,
      component: 'elInput',
      rules: mapVerify(['positiveNum']),
      label: `${that.$t('lang.gles.base.width')}(mm)`,
      clearable: true
    },
    // 高
    {
      name: 'height',
      value: row.height || '',
      span: 8,
      component: 'elInput',
      rules: mapVerify(['positiveNum']),
      label: `${that.$t('lang.gles.base.high')}(mm)`,
      clearable: true
    },
    // 体积
    {
      name: 'volume',
      value: row.volume || '',
      span: 8,
      component: 'elInput',
      rules: mapVerify(['positiveNum']),
      label: `${that.$t('lang.gles.common.volume')}(mm³)`,
      clearable: true
    },
    // 重量
    {
      name: 'weight',
      value: row.weight || '',
      span: 8,
      component: 'elInput',
      rules: mapVerify(['positiveNum']),
      label: `${that.$t('lang.gles.common.weight')}(g)`,
      clearable: true
    },
    // 物料条形码
    {
      name: 'materialBarcode',
      value: row.materialBarcode || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.material.materialBarcode'),
      rules: mapVerify(['inputLen50']),
      clearable: true
    },
    // 图片地址
    {
      name: 'image',
      value: row.image || '',
      span: 8,
      component: '',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.imgUrl')
    }
  ]
  basicFormData.forEach((item) => {
    if (mode === 'detail') {
      item.disabled = true
    }
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return basicFormData
}
// 获取物料控制信息
export const getEditControlFormData = (that, options, row) => {
  const controlFormData = [
    {
      title: that.$t('lang.gles.material.turnoverContainerManage'),
      describe: that.$t('lang.gles.material.materialControlInfoTips'),
      children: [
        // 带容器周转
        {
          name: 'turnoverContainerEnable',
          value: row.turnoverContainerEnable === 1 ? true : false || false,
          span: 9,
          component: 'elCheckbox',
          label: that.$t('lang.gles.material.withTurnoverContainer'),
          mchange(val, item, cb) {
            val ? that.$set(that.formModel, item.name, val) : cb({ turnoverContainerTypeIds: [] })
          }
        },
        // 周转容器类型
        {
          name: 'turnoverContainerTypeIds',
          value: row.turnoverContainerTypeIds || [],
          span: 9,
          component: 'elSelect',
          multiple: true,
          label: that.$t('lang.gles.material.turnoverContainerType'),
          data: options.containerTypeList || [],
          clearable: true,
          rules: row.turnoverContainerEnable ? mapVerify(['required']) : [],
          condition(formModel) {
            return !!formModel.turnoverContainerEnable
          }
        }
      ]
    },
    {
      title: that.$t('lang.gles.material.batchPropertyManage'),
      children: [
        // 管理批属性
        {
          name: 'batchPropertyEnable',
          value: row.batchPropertyEnable === 1 ? true : false || false,
          span: 9,
          component: 'elCheckbox',
          label: that.$t('lang.gles.material.manageBatchProperty'),
          mchange(val, item, cb) {
            val ? that.$set(that.formModel, item.name, val) : cb({ batchPropertyId: '' })
          }
        },
        // 批属性
        {
          name: 'batchPropertyId',
          value: row.batchPropertyId || '',
          span: 9,
          component: 'elSelect',
          label: that.$t('lang.gles.material.batchProperty'),
          data: options.batchPropertyList || [],
          clearable: true,
          rules: row.batchPropertyEnable ? mapVerify(['required']) : [],
          condition(formModel) {
            return !!formModel.batchPropertyEnable
          }
        }
      ]
    },
    {
      // 有效期管理
      title: that.$t('lang.gles.material.validityPeriodManage'),
      children: [
        // 管理有效期
        {
          name: 'expirationEnable',
          value: row.expirationEnable === 1 ? true : false || false,
          span: 9,
          component: 'elCheckbox',
          label: that.$t('lang.gles.material.manageValidityPeriod'),
          mchange: that.onFormChange
        },
        // 有效期单位
        {
          name: 'expirationUnit',
          value: row.expirationUnit || 1,
          span: 9,
          component: 'elSelect',
          label: that.$t('lang.gles.material.validityUnit'),
          data: options.expirationUnitList || [],
          clearable: true,
          rules: row.expirationEnable ? mapVerify(['required']) : []
        },
        // 效期类型
        {
          name: 'expirationType',
          value: row.expirationType || '',
          span: 9,
          component: 'elSelect',
          label: that.$t('lang.gles.material.validityType'),
          data: options.expirationTypeList || [],
          clearable: true,
          rules: row.expirationEnable ? mapVerify(['required']) : [],
          mchange: that.onFormChange
        },
        // 有效期时长
        {
          name: 'expiration',
          value: row.expiration || '',
          span: 9,
          component: 'elInput',
          label: that.$t('lang.gles.material.validityDuration'),
          rules: row.expirationEnable && row.expirationType && row.expirationType !== 2 ? mapVerify(['required', 'positiveInteger', 'inputNumberLen20']) : mapVerify(['positiveInteger', 'inputNumberLen20']),
          clearable: true,
          condition(formModel) {
            return String(formModel.expirationType) !== '2'
          }
        },
        // 效期剩余 预警
        {
          name: 'expirationRemainAlert',
          value: row.expirationRemainAlert || '',
          span: 9,
          adaptionw: true,
          component: 'elInput',
          placeholder: that.$t('lang.gles.material.enterAlertDuration'),
          label: that.$t('lang.gles.material.remainingValidity'),
          rules: mapVerify(['positiveInteger', 'inputNumberLen20']),
          appendSlotName: 'expirationRemainAlert',
          clearable: true
        },
        // 效期剩余 拒存
        {
          name: 'expirationRemainRejectIn',
          value: row.expirationRemainRejectIn || '',
          span: 9,
          adaptionw: true,
          component: 'elInput',
          label: that.$t('lang.gles.material.remainingValidity'),
          rules: mapVerify(['positiveInteger', 'inputNumberLen20']),
          appendSlotName: 'expirationRemainRejectIn',
          clearable: true
        },
        // 效期剩余 拒取
        {
          name: 'expirationRemainRejectOut',
          value: row.expirationRemainRejectOut || '',
          span: 9,
          adaptionw: true,
          component: 'elInput',
          label: that.$t('lang.gles.material.remainingValidity'),
          rules: mapVerify(['positiveInteger', 'inputNumberLen20']),
          appendSlotName: 'expirationRemainRejectOut',
          clearable: true
        }
      ]
    }
  ]
  if (that.mode === 'detail') {
    controlFormData.forEach((item) => {
      item.children.forEach(cv => {
        // cv.mchange = that.onFormChange
        cv.disabled = true
      })
    })
  }
  return controlFormData
}

