<template>
  <div class="basic-data-add">
    <header-path :paths="headerPaths" />
    <el-card class="add-card">
      <m-form ref="myForm" :form-data="materialFormData" label-position="right" :label-width="120" :extend-config="extendFormConfig" />
      <div class="materialInfo">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane :label="$t('lang.gles.common.basicInfo')" name="first">
            <m-form ref="myBasicForm" :form-data="basicFormData" label-position="right" :label-width="120" :extend-config="extendFormConfig">
              <!-- 上传图片 -->
              <template #appendComponent>
                <upload-img />
              </template>
            </m-form>
          </el-tab-pane>
          <el-tab-pane :label="$t('lang.gles.common.controlInfo')" name="second">
            <m-form
              ref="myControlForm"
              :form-data="controlFormData"
              label-position="right"
              :label-width="120"
              :extend-config="extendControlFormConfig"
            >
              <template #expirationRemainAlert>
                <span class="tip"> {{ $t('lang.gles.material.earlyWarning') }} </span>
              </template>
              <template #expirationRemainRejectIn>
                <span class="tip-rest">
                  {{ $t('lang.gles.material.refuseDsposit') }}
                  <el-tooltip
                    popper-class="workshop_code_tip-icon"
                    effect="dark"
                    :content="$t('lang.gles.material.vaildPriodGtRemainingDeposited')"
                    placement="right"
                  >
                    <i class="el-icon-tip el-icon-question" />
                  </el-tooltip>
                </span>
              </template>
              <template #expirationRemainRejectOut>
                <span class="tip-rest">
                  {{ $t('lang.gles.material.refuseTake') }}
                  <el-tooltip
                    popper-class="workshop_code_tip-icon"
                    effect="dark"
                    :content="$t('lang.gles.material.vaildPriodGtRemainingTaked')"
                    placement="right"
                  >
                    <i class="el-icon-tip el-icon-question" />
                  </el-tooltip>
                </span>
              </template>
            </m-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="footer-btns">
        <div class="btns">
          <el-button v-debounce="onSubmit" type="primary">
            {{ $t('lang.gles.common.confirm') }}
          </el-button>
          <el-button @click="onCancel">
            {{ $t('lang.gles.common.cancel') }}
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import HeaderPath from '@/components/headerPath/index.vue'
import UploadImg from '@/components/upload/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import myTransform from '@/libs_sz/utils/transform'
import { getEditBaseFormData, getEditBasicFormData, getEditControlFormData } from '../data'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AddMaterialFile',
  components: {
    HeaderPath,
    MForm,
    UploadImg
  },
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    options: {
      type: Object,
      default: () => {}
    },
    rowDetail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      headerPaths: this.mode === 'add' ? [this.$t('lang.gles.material.materialAdd')] : [this.$t('lang.gles.material.materialEdit')],
      activeName: 'first',
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      extendControlFormConfig: {
        isNeedBtn: false,
        isGroup: true
      },
      formModel: {},
      classifySecondList: [],
      containerTypeList: [],
      batchPropertyList: []
    }
  },
  computed: {
    ...mapState('materialManage', ['measuringUnitList', 'expirationTypeList', 'expirationUnitList']),
    materialFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...row })
    },
    basicFormData() {
      const row = this.rowDetail || {}
      const options = {
        measuringUnitList: this.measuringUnitList,
        ...this.options
      }
      return getEditBasicFormData(this, options, { ...row })
    },
    controlFormData() {
      const options = {
        containerTypeList: this.containerTypeList,
        batchPropertyList: this.batchPropertyList,
        expirationTypeList: this.expirationTypeList,
        expirationUnitList: this.expirationUnitList
      }
      const row = this.rowDetail || {}
      return getEditControlFormData(this, options, { ...row })
    }
  },
  watch: {
    'formModel.length'() {
      this.$nextTick(() => {
        const formModel = this.$refs.myBasicForm.formModel
        if (formModel.length && formModel.width && formModel.height) {
          const volume = Number(formModel.length) * Number(formModel.width) * Number(formModel.height)
          this.$set(formModel, 'volume', volume)
        }
      })
    },
    'formModel.width'() {
      this.$nextTick(() => {
        const formModel = this.$refs.myBasicForm.formModel
        if (formModel.length && formModel.width && formModel.height) {
          const volume = Number(formModel.length) * Number(formModel.width) * Number(formModel.height)
          this.$set(formModel, 'volume', volume)
        }
      })
    },
    'formModel.height'() {
      this.$nextTick(() => {
        const formModel = this.$refs.myBasicForm.formModel
        if (formModel.length && formModel.width && formModel.height) {
          const volume = Number(formModel.length) * Number(formModel.width) * Number(formModel.height)
          this.$set(formModel, 'volume', volume)
        }
      })
    }
  },
  created() {
    if (!this.measuringUnitList?.length) {
      this.getMeasuringUnitList('materialMeasuringUnit')
    }
    if (!this.expirationTypeList?.length) {
      this.getExpirationType('materialExpirationType')
    }
    if (!this.expirationUnitList?.length) {
      this.getExpirationUnit('materialExpirationUnit')
    }
    this.getContainerTypeList()
    this.getBatchPropertyList()
    if (this.mode === 'edit') {
      const row = this.rowDetail
      this.getClassifyByFirstClassify(row.classifyFirst)
      return
    }
    this.getClassifyByFirstClassify()
  },
  methods: {
    ...mapActions('materialManage', ['getMeasuringUnitList', 'getExpirationType', 'getExpirationUnit']),
    // 获取周转容器类型
    async getContainerTypeList() {
      const params = {
        containerTypePatternList: ['containerPatternBarrel', 'containerPatternCrate']
      }
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getContainerType'), params)
      if (code) return
      this.containerTypeList = myTransform.arrToOptions(data, 'containerTypeName', 'containerTypeId')
    },
    // 获取批属性下拉
    async getBatchPropertyList() {
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getBatchProperty_dict'))
      if (code) return
      this.batchPropertyList = myTransform.arrToOptions(data, 'batchPropertyName', 'batchPropertyId')
    },
    // 根据一级分类级联二级分类
    async getClassifyByFirstClassify(parentId) {
      const params = { level: 2 }
      parentId ? params.parentId = parentId : ''
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('queryClasifyByCodeOrName'), params)
      if (code) return
      this.classifySecondList = myTransform.arrToOptions(data, 'classifyName', 'id')
    },
    handleClick(tab, event) {
      // console.log(tab, event)
    },
    onSubmit() {
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        this.$refs.myBasicForm.getValidateFormModel().then(async(basicModel) => {
          this.$refs.myControlForm.getValidateFormModel().then(async(control) => {
            const params = { ...this.rowDetail, ...model, ...basicModel, ...control }
            Object.entries(params).forEach(([k, v]) => {
              // if ((v === '' || v === 0) && k !== 'materialStatus') {
              //   delete params[k]
              // }
              if (typeof v === 'boolean') {
                params[k] = params[k] ? 1 : 0
              }
            })
            if (params.turnoverContainerTypeIds) {
              params.turnoverContainerTypeIds = params.turnoverContainerTypeIds.join(',')
            }
            params.batchPropertyDetail && delete params.batchPropertyDetail
            console.log(params, 'params')
            this.mode === 'add'
              ? await this.insert(params)
              : await this.update(params)
          })
        })
      })
    },
    async insert(params) {
      const { code } = await this.$httpService(this.$apiStore.materialManage('addMaterial'), params)
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.onCancel()
    },
    async update(params) {
      const { code } = await this.$httpService(this.$apiStore.materialManage('updateMaterial'), params)
      if (code) return
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.onCancel()
    },
    // 取消
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'MaterialFileList',
        mode: ''
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.materialInfo {
  padding: 20px 30px;
}
.el-card__body {
  display: flex;
  flex-direction: column;
  .footer-btns {
    flex: 0 0 60px;
    // position: fixed;
    // left: 201px;
    bottom: 20px;
    z-index: 10;
    width: calc(100vw - 212px);
    height: 60px;
    line-height: 60px;
    margin-top: 10px;
    text-align: center;
  }
}
/deep/ .el-checkbox__label {
  display: none;
}
.tip {
  display: inline-block;
  position: absolute;
  right: -32px;
}
.tip-rest {
  display: inline-block;
  position: absolute;
  right: -50px;
}
</style>
