<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :value.sync="baseFormModel.codeOrName"
      :placeholder="$t('lang.gles.common.pleaseInputCodeOrName')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t('lang.gles.common.addNew') }}
        </el-button>
        <!-- <el-button @click="importMaterial">
          导入
        </el-button>
        <el-button @click="exportMaterial">
          导出
        </el-button> -->
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @delItem="delItem"
        @pageChange="pageChange"
      >
        <template #clickSlot="{ row }">
          <span class="list-detail-item" @click="handleDetail(row)">
            {{ row.materialCode }}
          </span>
        </template>
      </m-table>
    </el-card>
    <!-- 详情 -->
    <detail-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :row-detail="detailRow"
      :options="options"
      @updateCom="editItem"
    />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import DetailDialog from './detail'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import { mapState, mapActions } from 'vuex'
import myTransform from '@/libs_sz/utils/transform'
export default {
  name: 'MaterialList',
  components: {
    ColumnConfig,
    QueryView,
    MTable,
    DetailDialog
  },
  mixins: [listMixins],
  data() {
    return {
      tableData: [],
      baseFormModel: {
        codeOrName: ''
      },
      formModel: {},
      pageData: {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0
      },
      detailRow: {},
      detailDialogVisible: false,
      options: {},
      classifySecondList: [],
      materialClassifySecondList: []
    }
  },
  computed: {
    ...mapState('materialManage', [
      'materialClassifyFirstList',
      'basicUnitList'
    ]),
    ...mapState('base', ['statusFlagList']),
    moreQueryData: function () {
      return getMoreQueryFormData(this, { ...this.formModel })
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      const methods = {
        linefeed: this.linefeed
      }
      return getSearchTableItem(this, methods)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    if (!this.statusFlagList?.length) {
      this.$store.dispatch('base/getStatusFlagList')
    }
    this.doSearch(this.baseFormModel)
    this.initSelectOption()
  },
  methods: {
    ...mapActions('materialManage', ['getClassifyFirst', 'getBasicUnitList']),
    initSelectOption() {
      this.getClassifyFirst()
      this.getClassifyByFirstClassify()
      this.getBasicUnitList('materialBasicUnit')
    },
    // 根据一级分类级联二级分类
    async getClassifyByFirstClassify(parentId) {
      const params = { level: 2 }
      parentId ? (params.parentId = parentId) : ''
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('queryClasifyByCodeOrName'),
        params
      )
      if (code) return
      this.classifySecondList = myTransform.arrToOptions(
        data,
        'classifyName',
        'id'
      )
      if (!parentId)
        this.materialClassifySecondList = myTransform.arrToOptions(
          data,
          'classifyName',
          'id'
        )
    },
    // 查询
    async doSearch(params, api = 'getMaterialListByCodeOrName') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.materialManage(api, this.pageData),
          params
        )
        if (code) return
        this.$store.commit(types.IS_LOADING, false)
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    // 新增
    updateCom() {
      const options = {
        basicUnitList: this.basicUnitList,
        materialClassifyFirstList: this.materialClassifyFirstList
      }
      this.$emit('updateCom', {
        currentCom: 'MaterialFileAdd',
        mode: 'add',
        options
      })
    },
    // 导入
    importMaterial() {},
    // 导出
    exportMaterial() {},
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 更多查询
     */
    handleMoreSearch(data) {
      this.formModel = { ...data }
      this.doSearch({ ...data }, 'getMaterialList')
    },
    /**
     * 更多查询重置方法
     * @param {*} data
     */
    handleReset(data) {
      this.formModel = {}
      // 更新级联下拉选项
      this.getClassifyByFirstClassify()
    },
    async editItem({ row }) {
      const options = {
        basicUnitList: this.basicUnitList,
        materialClassifyFirstList: this.materialClassifyFirstList
      }
      // 获取物料详情
      const { data, code } = await this.$httpService(
        this.$apiStore.materialManage('queryMaterialDetail'),
        { id: row.id }
      )
      if (code) return
      data.turnoverContainerTypeIds =
        data.turnoverContainerTypeIds &&
        data.turnoverContainerTypeIds.split(',').map((item) => Number(item))
      this.$emit('updateCom', {
        currentCom: 'MaterialFileAdd',
        mode: 'edit',
        options,
        row: { ...row, ...data }
      })
    },
    // 查看详情
    async handleDetail(row) {
      this.detailDialogVisible = true
      // 获取物料详情
      const { data, code } = await this.$httpService(
        this.$apiStore.materialManage('queryMaterialDetail'),
        { id: row.id }
      )
      if (code) return
      data.turnoverContainerTypeIds =
        data.turnoverContainerTypeIds &&
        data.turnoverContainerTypeIds.split(',').map((item) => Number(item))
      this.detailRow = { ...row, ...data }
      this.mode = 'detail'
      this.options = {
        basicUnitList: this.basicUnitList,
        materialClassifyFirstList: this.materialClassifyFirstList,
        materialClassifySecondList: this.materialClassifySecondList
      }
    },
    // 删除商品
    async delItem({ row }) {
      const { code } = await this.$httpService(
        this.$apiStore.materialManage('deleteMaterial'),
        { id: row.id }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      if (this.tableData.length < 2) this.resetPageData()
      this.doSearch(this.baseFormModel)
    }
  }
}
</script>
