<template>
  <div class="material-file">
    <component
      :is="currentCom"
      :mode="mode"
      :options="options"
      :row-detail.sync="row"
      @updateCom="updateCom"
    />
  </div>
</template>
<script>
import MaterialFileList from './components/list.vue'
import MaterialFileAdd from './components/add.vue'
export default {
  components: {
    MaterialFileList,
    MaterialFileAdd
  },
  data() {
    return {
      currentCom: 'MaterialFileList',
      mode: '',
      options: {},
      row: {}
    }
  },
  methods: {
    updateCom({ currentCom, mode, options, row }) {
      this.currentCom = currentCom
      this.mode = mode
      this.options = options
      this.row = row
    }
  }
}
</script>
<style lang="scss" scoped></style>
