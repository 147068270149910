<template>
  <el-drawer
    :visible="visible"
    :title="title"
    size="80%"
    custom-class="gles-drawer"
    :with-header="false"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="drawer-wrapper">
      <div class="drawer-operate-btn">
        <el-button type="primary" @click="handleEdit">
          {{ $t('lang.gles.common.edit') }}
        </el-button>
        <el-button type="danger" @click="handleDelete">
          {{ $t('lang.gles.common.delete') }}
        </el-button>
        <i
          class="el-icon-close"
          @click="$emit('update:visible', false)"
        />
      </div>
      <section class="add-module">
        <!-- 表单信息 -->
        <m-form
          ref="myForm"
          :form-data="materialFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        />
      </section>
      <div class="materialInfo">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane :label="$t('lang.gles.common.basicInfo')" name="first">
            <m-form
              ref="myBasicForm"
              :form-data="basicFormData"
              label-position="right"
              :label-width="120"
              :extend-config="extendFormConfig"
            >
              <!-- 上传图片 -->
              <template #appendComponent>
                <upload-img />
              </template>
            </m-form>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('lang.gles.common.controlInfo')"
            name="second"
          >
            <m-form
              ref="myControlForm"
              :form-data="controlFormData"
              label-position="right"
              :label-width="120"
              :extend-config="extendControlFormConfig"
            >
              <template #expirationRemainAlert>
                <span class="tip">
                  {{ $t('lang.gles.material.earlyWarning') }}
                </span>
              </template>
              <template #expirationRemainRejectIn>
                <span class="tip-rest">
                  {{ $t('lang.gles.material.refuseDsposit') }}
                  <el-tooltip
                    popper-class="workshop_code_tip-icon"
                    effect="dark"
                    :content="
                      $t('lang.gles.material.vaildPriodGtRemainingDeposited')
                    "
                    placement="right"
                  >
                    <i class="el-icon-tip el-icon-question" />
                  </el-tooltip>
                </span>
              </template>
              <template #expirationRemainRejectOut>
                <span class="tip-rest">
                  {{ $t('lang.gles.material.refuseTake') }}
                  <el-tooltip
                    popper-class="workshop_code_tip-icon"
                    effect="dark"
                    :content="
                      $t('lang.gles.material.vaildPriodGtRemainingTaked')
                    "
                    placement="right"
                  >
                    <i class="el-icon-tip el-icon-question" />
                  </el-tooltip>
                </span>
              </template>
            </m-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import UploadImg from '@/components/upload/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import myTransform from '@/libs_sz/utils/transform'
import {
  getEditBaseFormData,
  getEditBasicFormData,
  getEditControlFormData
} from '../data'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'DetailMaterialFile',
  components: {
    MForm,
    UploadImg
  },
  mixins: [addMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'detail'
    },
    rowDetail: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      title: this.$t('lang.gles.material.materialDetail'),
      activeName: 'first',
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      extendControlFormConfig: {
        isNeedBtn: false,
        isGroup: true
      },
      formModel: {},
      containerTypeList: [],
      batchPropertyList: []
    }
  },
  computed: {
    ...mapState('materialManage', ['basicUnitList', 'measuringUnitList', 'materialClassifyFirstList', 'materialClassifySecondList', 'expirationTypeList', 'expirationUnitList']),
    materialFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...row })
    },
    basicFormData() {
      const row = this.rowDetail || {}
      const options = {
        basicUnitList: this.basicUnitList,
        measuringUnitList: this.measuringUnitList,
        materialClassifyFirstList: this.materialClassifyFirstList,
        materialClassifySecondList: this.materialClassifySecondList
      }
      return getEditBasicFormData(this, options, { ...row }, this.mode)
    },
    controlFormData() {
      const options = {
        containerTypeList: this.containerTypeList,
        batchPropertyList: this.batchPropertyList,
        expirationTypeList: this.expirationTypeList,
        expirationUnitList: this.expirationUnitList
      }
      const row = this.rowDetail || {}
      return getEditControlFormData(this, options, { ...row })
    }
  },
  watch: {
    materialFormData() {
      this.$nextTick(() => {
        this.$refs?.myForm?.changeFormValue(this.rowDetail ?? {})
      })
    },
    basicFormData() {
      this.$nextTick(() => {
        const basicDetail = {
          ...this.rowDetail,
          classifyFirst: this.rowDetail.classifyFirst || '',
          classifySecond: this.rowDetail.classifySecond || '',
          length: this.rowDetail.length || '',
          width: this.rowDetail.width || '',
          height: this.rowDetail.height || '',
          volume: this.rowDetail.volume || '',
          weight: this.rowDetail.volume || ''
        }
        this.$refs?.myBasicForm?.changeFormValue(basicDetail ?? {})
      })
    },
    controlFormData() {
      this.$nextTick(() => {
        const controlDetail = {
          ...this.rowDetail,
          turnoverContainerEnable: !!this.rowDetail.turnoverContainerEnable,
          batchPropertyEnable: !!this.rowDetail.batchPropertyEnable,
          expirationEnable: !!this.rowDetail.expirationEnable,
          batchPropertyId: this.rowDetail.batchPropertyId || '',
          expirationType: this.rowDetail.expirationType || '',
          expiration: this.rowDetail.expiration || '',
          expirationRemainAlert: this.rowDetail.expirationRemainAlert || '',
          expirationRemainRejectIn: this.rowDetail.expirationRemainRejectIn || '',
          expirationRemainRejectOut: this.rowDetail.expirationRemainRejectOut || ''
        }
        // console.log(controlDetail, 'control')
        this.$refs?.myControlForm?.changeFormValue(controlDetail ?? {})
      })
    }
  },
  created() {
    if (!this.basicUnitList?.length) {
      this.getBasicUnitList('materialBasicUnit')
    }
    if (!this.measuringUnitList?.length) {
      this.getMeasuringUnitList('materialMeasuringUnit')
    }
    if (!this.materialClassifyFirstList?.length) {
      this.getClassifyFirst()
    }
    this.getClassifySecond()
    if (!this.expirationTypeList?.length) {
      this.getExpirationType('materialExpirationType')
    }
    if (!this.expirationUnitList?.length) {
      this.getExpirationUnit('materialExpirationUnit')
    }
    this.getContainerTypeList()
    this.getBatchPropertyList()
  },
  methods: {
    ...mapActions('materialManage', [
      'getBasicUnitList',
      'getMeasuringUnitList',
      'getExpirationType',
      'getClassifyFirst',
      'getClassifySecond',
      'getExpirationUnit'
    ]),
    // 获取周转容器类型
    async getContainerTypeList() {
      const params = {
        containerTypePatternList: [
          'containerPatternTray',
          'containerPatternBarrel',
          'containerPatternCrate'
        ]
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerType'),
        params
      )
      if (code) return
      this.containerTypeList = myTransform.arrToOptions(
        data,
        'containerTypeName',
        'containerTypeId'
      )
    },
    // 获取批属性下拉
    async getBatchPropertyList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getBatchProperty_dict')
      )
      if (code) return
      this.batchPropertyList = myTransform.arrToOptions(
        data,
        'batchPropertyName',
        'batchPropertyId'
      )
    },
    handleClick(tab, event) {
      // console.log(tab, event)
    },
    // 取消
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'MaterialFileList',
        mode: ''
      })
    },

    handleEdit() {
      this.$emit('update:visible', false)
      this.$emit('updateCom', {
        currentCom: 'MaterialFileAdd',
        mode: 'edit',
        row: this.rowDetail
      })
    },
    /**
     * 删除
     */
    handleDelete() {
      this.$confirm(this.$t('lang.gles.common.deleteConfirmMsg0'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      })
        .then(() => {
          this.$emit('update:visible', false)
          this.$parent.delItem({ row: this.rowDetail })
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.materialInfo {
  padding: 20px 30px;
}
/deep/ .el-drawer__header > span {
  font-size: 16px;
  font-weight: 600;
}
.el-card__body {
  display: flex;
  flex-direction: column;
  .footer-btns {
    flex: 0 0 60px;
    // position: fixed;
    // left: 201px;
    bottom: 20px;
    z-index: 10;
    width: calc(100vw - 212px);
    height: 60px;
    line-height: 60px;
    margin-top: 10px;
    text-align: center;
  }
}
/deep/ .el-checkbox__label {
  display: none;
}
.tip {
  display: inline-block;
  position: absolute;
  right: -32px;
}
.tip-rest {
  display: inline-block;
  position: absolute;
  right: -50px;
}
</style>
